import React from 'react'
import styled from 'styled-components'
import {
    Layout,
    SEO,
    Container,
    SectionSpacer,
    LocaleConfig,
    useTranslations,
} from '../core'
import { ResponsiveVideo, Video } from '../core/ResponsiveVideo'
import { GrammarCategoryBadge } from './GrammarCategoryBadge'
import { Link } from 'gatsby'

interface PageLayoutProps {
    path: string
    pageContext: {
        content: any
        localeConfig: LocaleConfig
    }
}

const PageLayout = ({ path, pageContext }: PageLayoutProps) => {
    const { category, title, description, slug } = pageContext.content
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO
                title={title}
                extraMetas={[{ name: 'robots', content: 'noindex' }]}
            />
            <PageHeader />
            <Container>
                <VideoWrapper>
                    <ResponsiveVideo
                        controls
                        autoPlay
                        src={`https://www.cooori.com/media/grammar-videos/${slug}-720.mp4`}
                    />
                </VideoWrapper>
                <VideoDetails>
                    <GrammarCategoryBadge
                        locale={pageContext.localeConfig}
                        category={category}
                    />
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </VideoDetails>
                <Navigation>
                    <ReturnLink
                        to="/grammar-videos"
                        data-testid="grammar-videos-return-link"
                    >
                        {translate('link_grammar_videos_return')}
                    </ReturnLink>
                </Navigation>
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default PageLayout

const PageHeader = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.accent};
    height: 160px;
    z-index: -1;

    @media (max-width: 500px) {
        height: 72px;
    }
`

const VideoWrapper = styled.div`
    margin: -80px 0 32px 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        ${Video} {
            width: calc(100% + 24px);
            margin: 0 -12px 32px -12px;
        }
    }
`

const VideoDetails = styled.div`
    margin-bottom: 64px;
`

const Title = styled.h1`
    margin: 16px 0 30px 0;
    line-height: 48px;
    ${({ theme }) => theme.typography_presets.bold};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 20px;
        line-height: 30px;
        margin: 8px 0 24px 0;
    }
`

const Description = styled.p`
    margin: 0;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 14px;
        line-height: 26px;
    }
`

const Navigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ReturnLink = styled(Link)`
    display: inline-flex;
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.invertedBackground};
    border-radius: 3px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
    color: #4d4d4d;
    text-decoration: none;
    cursor: pointer;
    margin: 0 auto;
    padding: 0 24px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.invertedBackground};
        color: ${({ theme }) => theme.colors.invertedText};
    }
`
