import React from 'react'
import styled from 'styled-components'
import { LocaleConfig, useTranslations } from '../core'

export type GrammarCategory =
    | 'general_overview'
    | 'nouns'
    | 'adjectives_and_adverbs'
    | 'verbs'
    | 'pronouns'
    | 'conjunctions_and_prepositions'
    | 'parts_of_speech'
    | 'articles'
    | 'verbs_and_adjectives'
    | 'study_tips'

interface GrammarCategoryBadgeProps {
    locale: LocaleConfig
    category: GrammarCategory
}

export const GrammarCategoryBadge = ({
    locale,
    category,
}: GrammarCategoryBadgeProps) => {
    const { translate } = useTranslations(locale.locale)

    return (
        <Badge category={category}>
            {translate(`grammar_category_${category}`)}
        </Badge>
    )
}

const CATEGORY_COLORS: Record<GrammarCategory, string> = {
    general_overview: '#43AA8B',
    nouns: '#825196',
    adjectives_and_adverbs: '#86B22F',
    verbs: '#3775D9',
    pronouns: '#F17300',
    conjunctions_and_prepositions: '#F46A8C',
    parts_of_speech: '#D7565A',
    articles: '#C98566',
    verbs_and_adjectives: '#525885',
    study_tips: '#469EB0',
}

const Badge = styled.div<{ category: GrammarCategory }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 26px;
    background-color: ${({ category }) => CATEGORY_COLORS[category]};
    color: white;
    border-radius: 44px;
    font-size: 12px;
    line-height: 10px;
    padding: 0px 16px;
    ${({ theme }) => theme.typography_presets.bold};
`
